import request from '@/utils/request'
export function login(data) {
    return request({
        url:'/auth/login',
        method:'post',
        data
    })
}
export  function logout() {
    return request({
        url:'/auth/logout',
        method:'delete'
    })
}
export function thirdAuth(data) {
    return request({
        url:'/auth/thirdAuth',
        method:'post',
        data
    })
}
export function thirdLogin(data) {
    return request({
        url:'/auth/thirdLogin',
        method:'post',
        data
    })
}
export function getWxImgCode() {
    return request({
        url:'/auth/getWxImgCode',
        method:'get',
    })
}
export function getWxSignConfig(params){
    return request({
        url:'/auth/genWxWebSigin',
        params
    })
}
export function getWxUUID(params) {
    return request({
        url:'/auth/getWxUUID',
        method:'get',
        params
    })
}
export function getWxToken(params) {
    return request({
        url:'/auth/getWxToken',
        method:'get',
        params
    })
}
export function bindPhoneByCode(data) {
    return request({
        url:'/auth/bindByCode',
        method:'post',
        data
    })
}
export function sendBindCode(params){
	return request({
		url:'/auth/sendBindCode',
		method:'get',
		params
	})
}
export function sendRegisterCode(params) {
    return request({
        url:'/auth/sendRegisterCode',
        method:'get',
        params
    })
}
export function register(data) {
    return request({
        url:'/auth/register',
        method:'post',
        data
    })
}